<template>
  <div class="custom-card-with-border my-10" v-if="doctor_information.experiences">
    <div v-for="(experience, index) in doctor_information.experiences" :key="index">
      <h3 class="mb-0">{{ experience.institute_name }}</h3>
      <p class="mb-0 mt-0">Designation: {{ experience.designation }}</p>
      <p class="mb-0 mt-0">Department: {{ experience.department }}</p>
      <p class="mb-0 mt-0">Department Unit: {{ experience.department_unit_name }}</p>
      <p class="mb-0 mt-0">{{ experience.start_date }} - {{ experience.is_current_job == 1 ? 'Current' : experience.end_date}}</p>
      <el-divider border-style="double" />

    </div>
  </div>
</template>

<script>
export default {
  name: 'DoctorExprience',
  props: {
    doctor_information: {},
  },
};
</script>

<style scoped>

</style>
