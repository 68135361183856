<template>
  <div>
    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane v-for="(chamber, index) in chambers" :key="index"
                   :label="`${chamber.institute_name}${chamber.room_no ? 'Room No. # '+chamber.room_no : ''}`"
                   :name="index">
        <h3>{{ chamber.institute_name }} {{ chamber.room_no ? 'Room No. # ' + chamber.room_no : '' }}</h3>
        <p>{{ chamber.address }}</p>
        <div style="margin-top: 20px">
          <div v-for="(slot, index) in chamber.working_schedules" :key="index" v-if=" chamber.working_schedules">
            <el-radio-group v-if="slot.slots" size="small" :value="modelValue"
                            v-model="modelValue"
                            @click="$emit('workPlaceId',chamber,app_index)"
                            @input="$emit('update:modelValue', $event.target.value)">
              <el-radio :class="single_slog.status == 2 ||   single_slog.status == 0 ? 'bg-danger radio-btn-danger' : ''" :label="single_slog.slot_time" border
                        v-for="(single_slog, slot_index)  in slot.slots" :key="slot_index"
                        style="display: inline;margin: 5px 5px"
                        :disabled="single_slog.status == 2 || single_slog.status == 0">
                {{ single_slog.slot_time }}
              </el-radio>
            </el-radio-group>
            <el-empty description="No data found" v-else/>
          </div>
          <div v-else>
            <el-empty description="Slot not available"/>

          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: 'ChamberWithSlots',
  props: {
    chambers: {
      type: Array,
      default: false,
    },
    modelValue: '',
    select_chamber_id: '',
    app_index: {
      required: false,
      default: null,
    },
  },

  data() {
    return {
      activeName: 0,
      radio1: '',
      slot_time: '',
    };
  },
  methods: {
    handleClickAppointment(tab, event) {
      console.log('chamber', tab, event);
    },
    handleClick() {
      this.$emit('slotNull');
    },
  },
};
</script>

<style>

.radio-btn-danger {
  color: #fff !important;
  background: #f56c6c;
  border: 1px solid #f56c6c;
}
.radio-btn-danger .el-radio__label{
  color: #ffffff !important;
}
</style>
