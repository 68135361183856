<template>
  <div class="custom-card-with-border text-center">
    <h4 class="mt-0">FNF members</h4>
    <el-badge class="item mx-5" v-for="(fnf_member, index) in fnf_members" :key="index">
      <el-button class="m-5">{{ fnf_member.fullname }}</el-button>
    </el-badge>
  </div>
</template>

<script>
export default {
  name: 'FnfMembers',
  props: {
    fnf_members: {},
  },
};
</script>

<style scoped>
.custom-card-with-border p {
  font-size: 14px;
  color: #1a1a1a;
  line-height: 20px;
}
</style>
