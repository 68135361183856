<template>
  <div>
    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane label="Basic Info" name="basic_info">
        <BasicInfo :doctor_details="doctor_details"/>
      </el-tab-pane>
      <el-tab-pane label="FNF Members" name="fnf_member">
        <div>
          <FnfMembers :fnf_members="doctor_details.fnf_members"/>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Doctor Overview" name="doctor_overview">
        <div class="mt-10">
          <DoctorProfile :doctor_information="doctor_details.doctor_information"/>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Doctor Educations" name="doctor_edu">
        <div v-if="doctor_details.doctor_information">
          <DoctorEducation :doctor_information="doctor_details.doctor_information"/>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Doctor Experience" name="doctor_exe">
        <div v-if="doctor_details.doctor_information">
          <DoctorExeprience :doctor_information="doctor_details.doctor_information"/>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import FnfMembers from './FnfMembers';
import BasicInfo from './DoctorBasicInfo';
import DoctorProfile from './DoctorProfile';
import DoctorEducation from './DoctorEducation';
import DoctorExeprience from './DoctorExprience';

export default {
  name: 'DoctorDetailsComponent',
  props: {
    doctor_details: {},
  },
  components: {
    FnfMembers,
    BasicInfo,
    DoctorProfile,
    DoctorEducation,
    DoctorExeprience,
  },
  data() {
    return {
      details: {},
      activeName: 'basic_info',
    };
  },
  methods: {
    handleClick() {

    },
  },
};
</script>

<style scoped>

</style>
