<template>
  <div class="custom-card-with-border">
    <table>
      <tr>
        <td>Card Intro</td>
        <td> {{ doctor_information ? doctor_information.card_intro : '-' }}</td>
      </tr>
      <tr>
        <td>Profession</td>
        <td> {{ doctor_information ? doctor_information.profession_name : '-' }}</td>
      </tr>
      <tr>
        <td>Nature of service</td>
        <td> {{ doctor_information ? doctor_information.nature_of_service_name : '-' }}</td>
      </tr>
      <tr>
        <td>Total Consultation</td>
        <td> {{ doctor_information ? doctor_information.total_consultation : '-' }}</td>
      </tr>
      <tr>
        <td>Total Patient</td>
        <td> {{ doctor_information ? doctor_information.no_of_patient : '-' }}</td>
      </tr>
      <tr>
        <td>Total Prescription</td>
        <td> {{ doctor_information ? doctor_information.no_of_prescription : '-' }}</td>
      </tr>
      <tr>
        <td>Work Experience</td>
        <td> {{ doctor_information ? doctor_information.work_experiences : '-' }}</td>
      </tr>
      <tr>
        <td>Score</td>
        <td> {{ doctor_information ? doctor_information.score : '-' }}</td>
      </tr>
      <tr>
        <td>Level</td>
        <td> {{ doctor_information ? doctor_information.level : '-' }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'DoctorProfile',
  props: {
    doctor_information: {},
  },
};
</script>

<style scoped>
.doctor-profile-left-items p{
  padding: 20px 20px;

}
.text-center {
  text-align: center !important;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

th, td {
  text-align: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-collapse: collapse;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.text-right {
  text-align: right;
}
</style>
