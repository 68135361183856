<template>
  <el-form
      label-position="top"
      label-width="100px"
      :model="form"
      :rules="rules"
      ref="form">

    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item label="First name" prop="first_name">
          <el-input v-model="form.first_name"/>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="Last name" prop="last_name">
          <el-input v-model="form.last_name"/>
        </el-form-item>
      </el-col>
      <el-col :span="12" v-if="!patient">
        <el-form-item label="Phone number" prop="phone_number">

          <el-input v-model="form.phone_number" type="number">
            <template #prepend>+88</template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="Registration no">
          <el-input v-model="form.registration_number"/>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="Date of Birth" prop="dob" class="w-100">
          <div class="block w-100">
            <el-date-picker
                class="w-100"
                v-model="form.dob"
                type="date"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
            />
          </div>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="Gender" prop="gender">
          <el-select v-model="form.gender" class="w-100">
            <el-option label="Male" value="male"/>
            <el-option label="Female" value="female"/>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="Blood group">
          <el-select v-model="form.blood_group" class="w-100">
            <el-option label="A+" value="A+"/>
            <el-option label="B+" value="B+"/>
            <el-option label="O+" value="O+"/>
            <el-option label="AB+" value="AB+"/>
            <el-option label="A-" value="A-"/>
            <el-option label="B-" value="B-"/>
            <el-option label="O-" value="O-"/>
            <el-option label="AB-" value="AB-"/>
          </el-select>
        </el-form-item>
      </el-col>
      
      <el-col :span="12">
        <el-form-item label="Select Profession">
          <el-select v-model="form.nature_of_service" filterable class="w-100">
            <el-option
                v-for="item in medical_services"
                :key="item.title"
                :label="item.title"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="Select Location">
          <el-select v-model="form.location" filterable class="w-100">
            <el-option
                v-for="item in locations"
                :key="item.name"
                :label="item.name"
                :value="item.name"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="Select chronic diseases">
          <el-select
              class="w-100"
              v-model="form.chronic_disease_ids"
              multiple
              filterable
              allow-create
              default-first-option
              :reserve-keyword="false"
          >
            <el-option
                v-for="item in chronic_diseases"
                :key="item.disease_title"
                :label="item.disease_title"
                :value="item.id"
            />
          </el-select>
          <br>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="Patient image">
          <input type="file" ref="profile_img" id="profile_img">
        </el-form-item>
            <img :src="userPhoto(patient.image)" v-if="patient" alt="" style="height: 100px">

      </el-col>
      <el-col :span="12">
        <el-form-item label="Identity card">
          <input type="file" ref="identity_card" id="identity_card">
        </el-form-item>

            <img :src="userPhoto(patient.identity_card)" v-if="patient" alt="" style="height: 100px">

      </el-col>
      <el-col v-if="is_zero_club_foot">
        <el-form-item>
          <el-checkbox v-model="form.is_zero_club_foot_patient" label="Zero Club Foot Patient ?" size="large" border/>
        </el-form-item>

      </el-col>
      <el-col :span="12">
        <el-form-item>
          <el-checkbox v-model="form.is_employee" label="Is Employee ?" size="large"/>
        </el-form-item>
      </el-col>

      </el-row>
      <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item
                label="Select parent"
                prop="parent_id">
                <el-select
                    v-model="form.parent_id"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="Please enter phone number"
                    :remote-method="searchGlobalPatient"
                    :loading="loading"
                    clearable
                    class="w-100"
                >
                  <el-option
                      v-for="item in patients"
                      :key="item.id"
                      :label="item.fullname"
                      :value="item.id"
                  />
                </el-select>
              </el-form-item>
      </el-col>


      <el-col :span="12">
        <el-form-item label="Relationship with you">
          <el-select v-model="form.relationship_with" class="w-100">
            <el-option label="Father" value="Father"/>
            <el-option label="Mother" value="Mother"/>
            <el-option label="Brother" value="Brother"/>
            <el-option label="Sister" value="Sister"/>
            <el-option label="Spouse" value="Spouse"/>
            <el-option label="Son" value="Son"/>
            <el-option label="Daughter" value="Daughter"/>
            <el-option label="Uncle" value="Uncle"/>
            <el-option label="Aunt" value="Aunt"/>
            <el-option label="Grand Father" value="GrandFather"/>
            <el-option label="Grand Mother" value="GrandMother"/>
            <el-option label="None" value="None"/>
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="12" >
        <el-form-item>
          <el-checkbox v-model="form.is_corporate" label="Is Corporate ?" size="large" @click="getInstitute()"/>
        </el-form-item>
        <el-form-item label="Select Institute" v-if="form.is_corporate">
          <el-select v-model="form.institute_id" filterable class="w-100">
            <el-option
                v-for="item in institutes"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <el-form-item label="Registration Source" prop="platform">
          <el-select v-model="form.platform" class="w-100">
            <el-option label="Social" value="Social" />
            <el-option label="Sticker" value="Poster" />
            <el-option label="Doctor Referral" value="Doctor Referral" />
            <el-option label="Patient Referral" value="Patient Referral" />
            <el-option label="Employee Referral" value="Employee Referral" />
            <el-option label="NGO" value="NGO" />
            <!-- <el-option label="Others" value="Others" /> -->
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <el-form-item label="Registration Purpose" prop="reg_purpose">
          <el-select v-model="form.reg_purpose" class="w-100">
            <el-option label="ZCF" value="ZCF" />
            <el-option label="PHT" value="PHT" />
            <el-option label="CP" value="CP" />
            <el-option label="Autism" value="Autism" />
            <el-option label="Rickets/Bow Leg" value="Rickets/Bow Leg" />
            <el-option label="Dental" value="Dental" />
          </el-select>
        </el-form-item>
      </el-col>

    </el-row>
    <span class="dialog-footer">
        <el-button @click="closeModal">Cancel</el-button>
        <el-button type="primary" @click="submitForm('form')">
          Confirm
        </el-button>
      </span>
  </el-form>
</template>

<script>
import axios from 'axios';
import store from '../../store/index';

export default {
  name: 'PatientForm',
  props: {
    patient: {
      type: Object,
      required: false,
    },
    phone_number: {
      type: Number,
      required: false,
    },
    is_zero_club_foot: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      form: {
        id: '',
        // fullname: '',
        first_name: '',
        last_name: '',
        gender: '',
        registration_number: '',
        location: '',
        // parent_id: store.state.auth.user.id,
        is_partner: 1,
        phone_number: '',
        relationship_with: '',
        blood_group: '',
        dob: '',
        nature_of_service: '',
        chronic_disease_ids: '',
        user_type: 'patient',
        password: '123456',
        is_zero_club_foot_patient: '',
        is_employee: false,
        institute_id: '',
        is_corporate: false,
        parent_id: null,
        platform: null,
        reg_purpose: null
      },
      dialogFormVisible: false,
      medical_services: [],
      chronic_diseases: [],
      locations: [],
      rules: {
        first_name: [
          {
            required: true,
            message: 'Please input  name',
          },
        ],
        phone_number: [
          {
            required: true,
            min: 11,
            message: 'Please input  phone number',
          },
        ],
        gender: [
          {
            required: true,
            message: 'Please input  gender',
          },
        ],
        dob: [
          {
            required: true,
            message: 'Please input  Date of birth',
          },
        ],
        platform: [
          {
            required: true,
            message: 'Please Select a Registration Source',
          },
        ],
        reg_purpose: [
          {
            required: true,
            message: 'Please Select a Registration Purpose',
          },
        ],
      },
      tempDocument: [],
      preview_img: null,
      institutes: [],
      patients: [],
    };
  },
  created() {
    if (this.patient) {
      this.preview_img = this.patient.image;
      const patient = { ...this.patient };
      const real_number = patient.phone_number.slice(0);
      this.form = { ...this.patient };
      this.form.phone_number = parseInt(real_number);
      if(patient.parent) {
        this.form.parent_id = patient.parent_id;
        this.patients.push({
          id: patient.parent_id,
          fullname: patient.parent,
        });
        this.form.relationship_with = patient.relationship;
      }
      if (this.patient.nature_of_service_id) {
        this.form.nature_of_service = this.patient.nature_of_service_id;
      }
      this.getInstitute();
    }
    if (this.phone_number) {
      this.form.phone_number = this.phone_number;
    }
    this.getMedicalService();
    this.getLocation();
    this.getChronicDisease();
  },

  methods: {
    submitForm(formEl) {
      this.$refs[formEl].validate((valid) => {
        if (valid) {
          // this.form.phone_number = '+88' + this.form.phone_number;
          this.form.chronic_disease_ids = this.form.chronic_disease_ids ? this.form.chronic_disease_ids.toString() : '';
          if (!this.form.chronic_disease_ids) {
            delete this.form.chronic_disease_ids;
          }

          const profile_img = this.$refs.profile_img.files ? this.$refs.profile_img.files[0] : '';
          const identity_card = this.$refs.identity_card.files ? this.$refs.identity_card.files[0] : '';
          const form = new FormData();

          form.append('first_name', this.form.first_name);

          form.append('last_name', this.form.last_name ? this.form.last_name : '');

          form.append('gender', this.form.gender);

          if (this.form.registration_number) {
            form.append('registration_number', this.form.registration_number);
          }

          if (this.form.location) {
            form.append('location', this.form.location);
          }

          
          form.append('parent_id', this.form.parent_id ? this.form.parent_id : store.state.auth.user.id);
          

          form.append('is_partner', 1);

          if (!this.form.id) {
            form.append('phone_number', `+88${this.form.phone_number}`);
          }

          if (this.form.relationship_with) {
            form.append('relationship_with', this.form.relationship_with);
          }

          form.append('blood_group', this.form.blood_group);
          form.append('dob', this.form.dob);
          if (this.form.nature_of_service) {
            form.append('nature_of_service', this.form.nature_of_service);
          }

          if (this.form.chronic_disease_ids) {
            form.append('chronic_disease_ids', this.form.chronic_disease_ids);
          }
          form.append('user_type', this.form.user_type);
          if (this.form.password) {
            form.append('password', this.form.password);
          }
          if (this.form.is_zero_club_foot_patient) {
            form.append('is_zero_club_foot_patient', this.form.is_zero_club_foot_patient);
          }
          if (this.form.institute_id) {
            form.append('institute_id', this.form.institute_id);
          }
          if (this.form.platform) {
            form.append('platform', this.form.platform);
          }
          if (this.form.reg_purpose) {
            form.append('reg_purpose', this.form.reg_purpose);
          }

          form.append('is_employee', this.form.is_employee);
          form.append('is_corporate', this.form.is_corporate);
          form.append('created_by', store.state.auth.user.id);

          if (profile_img && !this.form.id) {
            form.append('image', profile_img);
          } else if (this.form.id && profile_img) {
            form.append('profile_img', profile_img);
          }

          if (identity_card) {
            form.append('identity_card', identity_card);
          }

          let url = '';
          if (this.form.id) {
            url = `/api/v1/patient/profile/update/${this.form.id}?_method=put`;
          } else {
            url = '/api/v1/patient/create';
          }
          axios.post(url, form)
            .then((response) => {
              if (response.data.status_code == 200) {
                if (this.form.is_zero_club_foot_patient) {
                  return this.$router.push(`/zero-club-foot-patient/registration/${response.data.data.id}`);
                }
                this.$emit('dialogClose', this.dialogFormVisible = false);

                this.$emit('selectedPatient', response.data.data);
                this.$notify({
                  title: 'Success',
                  message: 'Succesfully Save',
                  type: 'success',
                  duration: 2000,
                });
                if (this.patient.id) {
                  this.form = response.data.data.user;
                } else {
                  this.form = {};
                }
              } else {
                this.$notify({
                  title: 'Failed',
                  message: response.data.message,
                  type: 'danger',
                  duration: 2000,
                });
              }
            });
        } else {
          return true;
        }
      });
    },
    getMedicalService() {
      axios.get('/api/v1/medical/services/list')
        .then((response) => {
          this.medical_services = response.data.data;
        });
    },
    getLocation() {
      axios.get('/api/v1/locations?location_type=townwithcity')
        .then((response) => {
          this.locations = response.data.location;
        });
    },
    getChronicDisease() {
      axios.get('/api/v1/chronic/disease/list')
        .then((response) => {
          this.chronic_diseases = response.data.data;
        });
    },
    closeModal() {
      if(this.form.id) {
        this.$router.go(-1);
      } else {
        this.$emit('dialogClose', this.dialogFormVisible = false);
      }
    },
    fileChange(file) {
      this.tempDocument.push(file.raw);
    },
    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}${img}`;
      }
    },
    getInstitute() {
      axios.get('/api/v1/institute/list?limit=7000')
        .then((res) => {
          this.institutes = res.data.data;
        });
    },
    searchGlobalPatient(value) {
      if (value) {
        axios
          .get(`/api/v1/patient/subordinate/list?term=${value}`)
          .then((response) => {
            this.patients = response.data.data;
          });
      }
    },
  },
};
</script>

<style>
.w-100 {
  width: 100%;
}
</style>
