export const CommonFunction = {
  methods: {
    phoneNumberHide(number) {
      if (number) {
        return number.slice(0, 2) + number.slice(2)
          .replace(/.(?=...)/g, '*');
      }
      return false;
    },
  },
};
