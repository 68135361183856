<template>
  <div class="text-center" v-if="doctor">
    <h3>Do you want to book a visit appointment with
      <el-button type="primary"> {{ doctor.fullname }}</el-button>
    </h3>
    <div>
      <el-badge class="item">
        <el-button>{{ date_value }} </el-button>
        <el-button>{{ slot }} </el-button>
      </el-badge>
    </div>
<!--    <h4 v-if="doctor">Fee amount {{ doctor.doctor_information }}</h4>-->
  </div>
</template>

<script>
export default {
  name: 'AppointmentDetails',
  props: {
    doctor: {},
    date_value: {},
    slot: {},
  },
};
</script>

<style scoped>

</style>
