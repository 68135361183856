<template>
  <div class="basic-info custom-card-with-border my-10">
    <el-row :getter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="10" class="my-10">
        <div>
          <el-avatar shape="square" class="user_image" :size="100"
                     v-if="doctor_details.image && doctor_details.image != '/storage/'">
            <img :src="userPhoto(doctor_details.image)" alt="Orko Health Ltd"/>
          </el-avatar>
          <el-avatar :size="40" v-else>
            <img
                src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" alt="Orko"
            />
          </el-avatar>
          <div>
            <el-tooltip
                class="box-item"
                effect="dark"
                :content="`${doctor_details.otp_verified == 1 ? 'Otp verified' : 'Otp not verified'}`"
                placement="bottom">
              <el-button :type="`${doctor_details.otp_verified == 1 ? 'success' : 'danger'}`" size="small">
                <el-icon>
                  <font-awesome-icon icon="fa-solid fa-phone-volume" />
                </el-icon>
              </el-button>
            </el-tooltip>
            <el-tooltip
                class="box-item"
                effect="dark"
                :content="`${doctor_details.confirmed == 1 ? 'Confirmed' : 'Not Confirmed'}`"
                placement="bottom">
              <el-button :type="`${doctor_details.confirmed == 1 ? 'success' : 'danger'}`" size="small">
                <el-icon>
                  <CircleCheck/>
                </el-icon>
              </el-button>
            </el-tooltip>
          </div>
        </div>
      </el-col>

      <el-col :xs="24" :sm="24" :md="12" :lg="14" class="my-10">
        <div class="doctor_basic_details">
          <h3 class="mb-0 mt-0">{{ doctor_details.fullname }}</h3>
          <p class="mb-0 mt-0">{{ phoneNumberHide(doctor_details.phone_number) }}</p>
          <p class="mb-0 mt-0">{{ doctor_details.town_name }} , {{
              doctor_details.region
            }},{{ doctor_details.country_name }}</p>
          <p class="mb-0 mt-0">Blood Group: {{ doctor_details.blood_group }}</p>
          <p class="mb-0 mt-0">Gender: {{ doctor_details.gender }}</p>
          <p class="mb-0 mt-0">Age: {{ doctor_details.age }}</p>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { CommonFunction } from '@/mixins/CommonFunction';

export default {
  name: 'DoctorBasicInfo',
  mixins: [CommonFunction],
  props: {
    doctor_details: {},
  },
  methods: {
    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}${img}`;
      }
    },
  },
};
</script>

<style scoped>
.doctor_basic_details {
  margin-left: 30px;
  line-height: 25px;
}

.doctor_basic_details p {
  font-size: 14px;
  color: #1a1a1a;
  line-height: 20px;
}

</style>
