<template>
  <div class="custom-card-with-border my-10" v-if="doctor_information.education">
      <div v-for="(education, index) in doctor_information.education" :key="index">
        <h3 class="mb-0">{{ education.institute }}</h3>
        <p class="mb-0 mt-0">Degree: {{ education.degree_name }}</p>
        <p class="mb-0 mt-0">Speciality: {{ education.speciality }}</p>
        <p class="mb-0 mt-0">Sub Specialty: {{ education.sub_speciality }}</p>
        <p class="mb-0 mt-0">{{ education.starting_year }}-{{ education.passing_year }}</p>
        <el-divider border-style="double" />
      </div>
  </div>
</template>

<script>
export default {
  name: 'DoctorEducation',
  props: {
    doctor_information: {},
  },
};
</script>

<style scoped>

</style>
